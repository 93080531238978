@import url('./index.css');

.web .no-padding {
	height: -moz-fit-content;
	height: fit-content;
}
.web ::-webkit-scrollbar {
	width: 10px;
	height: 10px;
}

.web ::-webkit-scrollbar-track {
	background: var(--okx-background-color);
	border-radius: 0;
}

.web ::-webkit-scrollbar-thumb {
	background: var(--ion-color-gray);
	border-radius: 0;
}

ion-app.ion-page.web {
	background: var(--okx-box-wrapper-background);
	/* background-image: url(../assets/images/page-01.jpg);
	background-repeat: no-repeat;
	background-position: 50% 0;
	background-size: cover; */
}

.web .web-only {
	display: block;
}

.web .static ion-toolbar.primary-toolbar {
	--background: transparent none;
}

.web .mbsc-select-input {
	cursor: pointer;
}

.web .mbsc-select-input[disabled],
.web .mbsc-sel-hdn[disabled] + .mbsc-select-input {
	cursor: default;
}

.web #main > ion-content:not(.route-order) > .no-padding > .absolute-content > .flex-min:last-of-type > ion-button,
.web #main > ion-content:not(.route-order) > .ion-padding > .absolute-content > .flex-min:last-of-type > ion-button,
.web #main > ion-content:not(.route-order-summary) > .no-padding > .absolute-content > .flex-min:last-of-type > ion-button,
.web #main > ion-content:not(.route-order-summary) > .ion-padding > .absolute-content > .flex-min:last-of-type > ion-button,
.web .restaurant-card ion-card-content ion-button:last-of-type {
	margin: 0;
}
.web ion-header.route-order-completed > ion-toolbar:last-child,
.web ion-header.route-checkout > ion-toolbar:last-child {
	display: block !important;
}
/*
.web #main > ion-header,
.web #main > ion-content {
	-webkit-animation: fadein 1s;
	-moz-animation: fadein 1s;
	-ms-animation: fadein 1s;
	-o-animation: fadein 1s;
	animation: fadein 1s;
}

@keyframes fadein {
	from { opacity: 0; }
	to   { opacity: 1; }
}
@-moz-keyframes fadein {
	from { opacity: 0; }
	to   { opacity: 1; }
}
@-webkit-keyframes fadein {
	from { opacity: 0; }
	to   { opacity: 1; }
}
@-ms-keyframes fadein {
	from { opacity: 0; }
	to   { opacity: 1; }
}
@-o-keyframes fadein {
	from { opacity: 0; }
	to   { opacity: 1; }
}
*/

/* responsive */
.web ion-modal.modal-classic.service-charge-box-modal,
.web ion-modal.modal-classic.allergen-modal,
.web ion-modal.change-table-modal {
	--width: var(--okx-bsmodal-width-lg) !important;
}
@media screen and (max-width: 1200px) {
	.web
		#main
		> ion-content:not(.route-order):not(.route-dashboard):not(.route-delivery-options):not(.route-delivery-address-check):not(.route-locations):not(.route-refer-a-friend):not(.route-pick-up-point-check):not(.route-history)
		> .no-padding,
	.web #main > ion-content > .ion-padding {
		width: var(--okx-bsmodal-width-md);
		margin-left: calc(var(--okx-bsmodal-width-md) / -2);
	}
	.web .restaurant-card,
	.web #main > ion-content.route-history > .no-padding,
	.web .ion-padding,
	.web #main > ion-content.route-history > .no-padding,
	.web .raf-card,
	.web .delivery-options-card,
	.web #main > ion-content.route-loyalty > .no-padding {
		width: var(--okx-bsmodal-width-md);
	}
	.web .click-collect-dialog.show-up {
		width: var(--okx-bsmodal-width-md);
	}
	.web ion-modal.modal-classic.service-charge-modal,
	.web ion-modal.modal-classic.service-charge-box-modal,
	.web ion-modal.modal-classic.allergen-modal,
	.web ion-modal.change-table-modal {
		--width: var(--okx-bsmodal-width-md) !important;
	}
}

@media screen and (max-width: 960px) {
	.web
		#main
		> ion-content:not(.route-order):not(.route-dashboard):not(.route-delivery-options):not(.route-delivery-address-check):not(.route-locations):not(.route-refer-a-friend):not(.route-pick-up-point-check):not(.route-history)
		> .no-padding,
	.web #main > ion-content > .ion-padding,
	.web #main > ion-content.route-history > .no-padding,
	.web .ion-padding,
	.web #main > ion-content.route-history > .no-padding,
	.web .raf-card {
		width: var(--okx-bsmodal-width-sm);
		margin-left: calc(var(--okx-bsmodal-width-sm) / -2);
	}
	.web .restaurant-card,
	.web .delivery-options-card,
	.web #main > ion-content.route-loyalty > .no-padding {
		width: var(--okx-bsmodal-width-sm);
	}
	.web .click-collect-dialog.show-up {
		width: var(--okx-bsmodal-width-sm);
	}
	.web ion-modal.modal-classic.service-charge-modal,
	.web ion-modal.modal-classic.service-charge-box-modal,
	.web ion-modal.modal-classic.allergen-modal,
	.web ion-modal.change-table-modal {
		--width: var(--okx-bsmodal-width-sm) !important;
	}
}

/* responsive end */

/* big screen override */

@media screen and (min-width: 992px) {
	.web ion-header:not(.route-login) + ion-toolbar.dynamic-header,
	.web ion-header:not(.route-login) + .content-spinner + ion-toolbar.dynamic-header {
		top: 16px;
		width: 100px;
	}
}
.web #main > ion-content .ion-padding,
.web #main > ion-content .no-padding {
	user-select: none;
}
